import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { Link } from '@remix-run/react'
import classNames from 'classnames'
import Carousel from 'nuka-carousel/lib/carousel'
import type { FC } from 'react'

export type ITestimonials = {
  actionLink?: string
  designation: string
  givenBy: string
  id: number
  imageURL: string
  logoURL?: string
  reviewerImg?: string
  text: string
  labelText?: string
  className?: string
  actionLabel?: string
  hasReviewerImgBg?: boolean
  logoStyles?: string
  imageStyles?: string
}

const TestimonialSingle: FC<ITestimonials> = props => {
  const { hasReviewerImgBg = true } = props
  return (
    <div className={props.className ?? 'z-10 mx-4 h-full max-w-7xl bg-[#F6F9F6] p-1 shadow-lg lg:mx-auto lg:mb-2 lg:p-12'}>
      <div className="grid gap-8 py-8 px-4 md:grid-cols-[3fr_2fr]">
        <div className="flex flex-col justify-between gap-3">
          <div className="flex flex-col gap-8">
            <div>
              <span className="rounded-sm bg-brand-primary-300 px-2 py-1 text-sm font-bold uppercase text-brand-secondary">
                {props.labelText ?? 'What users say'}
              </span>
              <h4 className="mt-3 text-lg font-black leading-normal lg:text-lg">{props.text}</h4>
            </div>
            <div className="flex flex-row items-center gap-4">
              <img src={props.logoURL} alt="" title="" loading="lazy" className={props.logoStyles ?? 'hidden w-20'} />
              <div className="flex flex-col items-left gap-4">
                {props.reviewerImg ? (
                  <div className="">
                    <div className={classNames('flex h-12 items-center rounded-full', hasReviewerImgBg && 'bg-white')}>
                      <img src={props.reviewerImg} alt="" title="" loading="lazy" className="h-12" />
                    </div>
                  </div>
                ) : null}
                <p className="grid">
                  <span className="font-bold">{props.givenBy}</span>
                  <span>{props.designation}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:items-end">
          <div className="relative flex h-full justify-end text-right">
            <img src={props.imageURL} alt="" title="" loading="lazy" className={props.imageStyles} />
          </div>
          {props.actionLink ? (
            <a
              href={props.actionLink}
              target={'_blank'}
              rel="noreferrer noopener nofollow"
              className="mt-4 flex items-center justify-center pt-4 text-center font-bold no-underline  lg:justify-end">
              {props.actionLabel ?? 'See it in action'}
              <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4" />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  )
}

type TestimonialProps = {
  testimonials: ITestimonials[]
  labelText?: string
  actionLabel?: string
  className?: string
  showMoreStories?: boolean
}
const TestimonialCarousel = (props: TestimonialProps) => {
  const { testimonials, showMoreStories = true } = props
  return (
    <div className="relative my-14 w-full max-w-[22.5rem] md:max-w-7xl md:py-8">
      <Carousel
        autoplay={false}
        wrapAround
        speed={300}
        slidesToScroll={1}
        enableKeyboardControls
        zoomScale={1}
        className="pb-14"
        renderBottomCenterControls={({ currentSlide, previousSlide, slideCount }) => (
          <div className="hidden md:flex">
            {Array.from({ length: slideCount + 1 }, (_, i) => i)
              .slice(1)
              .map(slide => {
                const isActive = currentSlide === slide || currentSlide - slideCount === slide || currentSlide + slideCount === slide
                return (
                  <div
                    key={slide}
                    onClick={previousSlide}
                    className={classNames(
                      isActive ? 'bg-opacity-20' : 'bg-opacity-100',
                      'mx-1 mb-8 h-1 w-16 cursor-pointer bg-brand-secondary'
                    )}></div>
                )
              })}
          </div>
        )}
        defaultControlsConfig={{
          nextButtonClassName: 'hidden',
          prevButtonClassName: 'hidden'
        }}>
        {testimonials.map((testimonial: ITestimonials) => (
          <TestimonialSingle {...testimonial} {...props} key={testimonial.id} />
        ))}
      </Carousel>
      {showMoreStories && (
        <div className="absolute bottom-4 left-1/2 -ml-16 lg:bottom-20">
          <Link to="/" className="font-bold">
            See more stories
          </Link>
        </div>
      )}
    </div>
  )
}

export default TestimonialCarousel
